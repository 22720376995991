import { UnleashClient } from "unleash-proxy-client";
import { CLIENT_TOKENS, UNLEASH_SERVER_URL } from "./config/unleash-client-config";

type AppContext = "b2b" | "b2c" | "b2x";
type AppName = `${AppContext}-${string}-unleash`;

export const unleashClient = (appName: AppName, isDev = false): UnleashClient => {
  const client = new UnleashClient({
    url: UNLEASH_SERVER_URL,
    clientKey: isDev ? CLIENT_TOKENS.dev : CLIENT_TOKENS.prod,
    disableMetrics: !isDev,
    appName,
    impressionDataAll: true,
  });

  client.start();
  return client;
};
